import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import VideoPanel from '~2-components/VideoPanel/VideoPanel';

const VideoPanelKontent = (props) => {
    const { system } = props;
    const { id: videoPanelID } = system;

    const allVideoPanels = useStaticQuery(graphql`
        query allVideoPanels {
            allKontentItemVideoPanel {
                nodes {
                    elements {
                        introduction {
                            value
                        }
                        title {
                            value
                        }
                        video_type {
                            value {
                                name
                            }
                        }
                        video_id {
                            value
                        }
                    }
                    system {
                        id
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        allVideoPanels.allKontentItemVideoPanel.nodes.find((n) => videoPanelID === n.system.id)
    ), [ allVideoPanels, videoPanelID]);

    return (
        <VideoPanel
            videoSrc={match.elements.video_type.value[0].name}
            videoID={match.elements.video_id.value}
            videoHeading={match.elements.title.value}
            videoContent={match.elements.introduction.value}
        />
    );
};

VideoPanelKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.required
    }).isRequired
};

export default VideoPanelKontent;

