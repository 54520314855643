import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import VideoPanel from '~2-components/VideoPanel/VideoPanel';
import VideoPanelKontent from '~3-data-components/VideoPanelKontent';
import { vimeoExample, youtubeExample, noContentExample } from '~data/videoExample';

const videoEg = `import VideoPanel from '~2-components/VideoPanel/VideoPanel';

<VideoPanel
    videoSrc = '' //Required, either 'YouTube' or 'Vimeo'
    videoId = '' //Required
    heading = '' //Optional
    content = '' //Optional
/>
`;

const SgVideoPanel = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allKonentVideoPanels = useStaticQuery(
        graphql`
            query SGVideoPanels {
                allKontentItemVideoPanel {
                    nodes {
                        system {
                            id
                            type
                        }
                    }
                }
            }
        `);

    const allPanels = allKonentVideoPanels.allKontentItemVideoPanel.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>Video with Copy</h1>
                    <h2>How to use</h2>
                    <CodeBlock
                        code={videoEg}
                    />

                    <h2>Example</h2>
                </div>
            </section>
            <section className="project-examples">
                <div className="container">
                    <VideoPanel {...youtubeExample} />

                    <VideoPanel {...vimeoExample} />

                    <VideoPanel {...noContentExample} />
                </div>
            </section>

            <section className='sg-content'>
                <div className='container'>
                    <h2>All live Kontent examples</h2>
                </div>
            </section>
            <section className="project-examples">
                <div className="container">
                    {allPanels.map((videoPanel, index) => {
                        return <VideoPanelKontent key={index} {...videoPanel} />;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgVideoPanel;
