export const youtubeExample = {
    videoSrc: "YouTube",
    videoID: "ScMzIvxBSi4",
    videoHeading: 'YouTube video',
    videoContent: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis doloribus minus, et asperiores recusandae, exercitationem assumenda sunt sit, reiciendis est repellat nemo mollitia delectus numquam. Iure ratione mollitia quia illum.'
};

export const vimeoExample = {
    videoSrc: "Vimeo",
    videoID: "97630356",
    videoHeading: 'Vimeo Example',
    videoContent: `Have two paragraphs of lorem ipsum! Nobis doloribus minus, et asperiores recusandae, exercitationem assumenda sunt sit, reiciendis est repellat nemo mollitia delectus numquam. Iure ratione mollitia quia illum.`
};

export const noContentExample = {
    videoSrc: "Vimeo",
    videoID: "97630356"
};

export const allanExample = {
    videoSrc: "YouTube",
    videoID: "ScMzIvxBSi4",
    videoHeading: `Alan's Story`,
    videoContent: 'Alan was exposed to asbestos during the course of his employment as a maintenance worker, and was diagnosed with mesothelioma in June 2015.  Adviceline Injury Lawyers, Bree Knoester was able to bring a claim on behalf of Alan and his family which was resolved in a very short period of time.'
};
