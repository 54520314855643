import React from 'react';
import PropTypes from 'prop-types';

const VideoPanel = (props) => {
    let videoDiv, videoUrl;

    const {
        videoSrc,
        videoID,
        videoHeading,
        videoContent
    } = props;

    if (videoSrc == 'YouTube') {
        videoUrl = `https://www.youtube.com/embed/${videoID}`;
        videoDiv = <iframe src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
    } else {
        videoUrl = `https://player.vimeo.com/video/${videoID}`;
        videoDiv = <iframe src={videoUrl} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>;
    }

    return (
        <div className="video-panel">
            <div className="video-container ratio-16-9">
                {videoDiv}
            </div>
            {videoHeading &&
                <div className="video-copy">
                    <h2>{videoHeading}</h2>
                    {videoContent &&
                        <p>{videoContent}</p>
                    }
                </div>
            }
        </div>
    );
};

VideoPanel.propTypes = {
    videoSrc: PropTypes.string,
    videoID: PropTypes.string,
    videoHeading: PropTypes.string,
    videoContent: PropTypes.string
};

export default VideoPanel;

